<template>
  <v-select
    v-model="selection"
    :items="options"
    :label="fieldName"
    :placeholder="$translate('pleaseSelect')"
    :class="{ 'is-required': isRequired }"
    :rules="validationRules"
    :persistent-placeholder="true"
    class="select-field-renderer"
  >
    <template #item="{ item, props }">
      <v-list-item v-bind="props" :title="undefined">
        <v-list-item-title class="text-wrap">
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-select>
</template>

<script setup lang="ts">
import{ computed, onMounted, type PropType } from 'vue';
import { useDynamicValueGetSet } from '@/dynamic-form/composables/use-dynamic-value-get-set';
import { useIsRequired } from '@/dynamic-form/composables/use-is-required';
import type { ISelectInputData, IVisitFormInputField } from '@einfachgast/shared';
import { useExtractValidationRules } from '@/dynamic-form/composables/use-extract-validation-rules';
import { translate } from '@/plugins/translator';
import { useFieldname } from '@/dynamic-form/composables/use-fieldname';
import { useVisitConditionsStore } from '@/store/visit-conditions';
import { useGroupsStore } from '@/store/groups';
import { containsOption } from '@/helpers/select-options-helper';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  field: {
    type: Object as PropType<IVisitFormInputField<ISelectInputData>>,
    required: true,
    default: () => ({} as IVisitFormInputField),
  },
});
const { t } = useI18n();
const isRequired = useIsRequired(props.field);
const fieldName = useFieldname(props.field);
const options = computed(() => [
  { title: t('pleaseSelect'), value: null },
  ...props.field.data.options.map((option) => ({ title: translate(option.value), value: option.id })),
]);
const selection = useDynamicValueGetSet(props.field);
const groupsStore = useGroupsStore();
const selectedGroup = computed(() => groupsStore.selectedGroup);

const visitConditionsStore = useVisitConditionsStore();
visitConditionsStore.registerDenyOnValidator(
  () => {
    // Ignore if another group is selected, and the field will not be visible
    const defaultGroupId = groupsStore.defaultGroup?.id as string;
    if (props.field.groups?.length > 0 && !props.field.groups.includes(selectedGroup.value?.id ?? defaultGroupId)) {
      return true;
    }
    return props.field.data.denyOn.includes(selection.value as string) ? props.field.data.denyMessage : true;
  },
);

const validationRules = useExtractValidationRules(props.field);

onMounted(() => {
  if (!selection.value && props.field.data.defaultOption && containsOption(props.field.data.options, props.field.data.defaultOption)) {
    selection.value = props.field.data.defaultOption;
  }
});
</script>
